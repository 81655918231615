import { defineStore } from "pinia";
import { CANCELED, CONSULTATION, EXAM } from "@/constants";
import { useManagerApi } from "@/api";
import {
  useAppStore,
  usePatientStore,
  useScheduleFiltersStore,
  useSchedulesCartStore,
  useSchedulesStore,
} from "@/stores";

export const useCartStore = defineStore({
  id: "cart",
  state: () => ({
    cart:
      {
        id: null,
        client_id: null,
        merged_id: null,
        status: null,
        completed_at: null,
        expires_at: null,
        patient_id: null,
        health_plan_id: null,
        total: null,
        payment_data: null,
        created_at: null,
        updated_at: null,
        minutes_to_expire: null,
        cartItems: {
          data: [],
        },
      } || null,
    loading: false,
    error: null,
    hydrated: false,
  }),

  actions: {
    async createCart() {
      this.loading = true;
      const patientStore = usePatientStore();

      try {
        const scheduleFiltersStore = useScheduleFiltersStore();
        const schedulesStore = useSchedulesStore();

        const { data } = await useManagerApi().post(`nick-agenda/cart/new`, {
          health_plan_id: scheduleFiltersStore?.filters?.health_plan?.id,
          health_plan_category_external_id:
            scheduleFiltersStore?.filters?.health_plan_category?.external_id,
          attendance_type: scheduleFiltersStore?.filters?.attendance_type,
          reschedule_id: schedulesStore.toReschedule?.id,
          client_id: scheduleFiltersStore?.filters?.client_id,
        });

        this.cart = data.data;
        this.hydrated = true;
        patientStore.hydrate();
      } catch (e) {
        if (e?.response?.status === 410) {
          this.$reset();
          return;
        }

        this.error = e?.response?.data || e;
      } finally {
        this.loading = false;
      }
    },
    async getCart(cartId) {
      this.loading = true;

      try {
        const { data } = await useManagerApi().get(
          `nick-agenda/cart/${cartId}`
        );

        this.cart = data.data;
        this.hydrated = true;
      } catch (e) {
        if (e?.response?.status === 410) {
          this.$reset();
          return;
        }

        this.error = e?.response?.data || e;
      } finally {
        this.loading = false;
      }
    },

    async getCartPaymentStatus() {
      this.loading = true;

      try {
        const { data } = await useManagerApi().get(
          `nick-agenda/cart/${this.cart.id}/payment-status`
        );

        this.cart = data.data;
      } catch (e) {
        if (e?.response?.status === 410) {
          this.$reset();
          return;
        }

        this.error = e?.response?.data || e;
      } finally {
        this.loading = false;
      }
    },

    async hydrate(force = false) {
      if (this.hydrated && !force) return;

      const appStore = useAppStore();
      const patientStore = usePatientStore();

      if (
        appStore.authenticated === true &&
        patientStore.hasCurrentCart === false
      ) {
        return;
      }

      this.loading = true;

      let url = "nick-agenda/cart/session";
      if (
        appStore.authenticated === true &&
        patientStore.hasCurrentCart === true
      ) {
        url = `nick-agenda/cart/${patientStore.patient.current_cart_id}`;
      }

      try {
        const { data } = await useManagerApi().get(url);

        if (data.data !== null) {
          this.cart = data.data;
          this.hydrated = true;
        }
      } catch (e) {
        if (e?.response?.status === 410) {
          this.$reset();
          return;
        }

        this.error = e?.response?.data || e;
      } finally {
        this.loading = false;
      }
    },

    async dehydrate() {
      this.loading = true;

      try {
        this.resetCart();

        this.hydrated = false;
      } catch (e) {
        this.error = e;
      } finally {
        this.loading = false;
      }
    },

    async addToCart(key, item) {
      const parts = key.split("-");
      const type = parts[0];
      const procedureOrSpecialityId = parts[1];

      return await useManagerApi()
        .post(`nick-agenda/cart/${this.cart.id}/items`, {
          available_slot_id: item.id,
          doctor_id: item.doctor_id,
          speciality_id:
            type === CONSULTATION ? procedureOrSpecialityId : null,
          medical_procedure_id: type === EXAM ? procedureOrSpecialityId : null,
        })
        .then((response) => {
          const schedulesCartStore = useSchedulesCartStore();

          schedulesCartStore.addAvailableSlot(item);

          this.$patch({ cart: response.data.data });

          return response;
        })
        .catch((e) => {
          if (e?.response?.data) {
            const response = e.response.data;
            if (
              response.status === 422 &&
              response.detail === "available_slot_already_in_the_cart"
            ) {
              return;
            }
          }

          this.error = e?.response?.data || e;

          throw e;
        });
    },

    deleteFromCart(id) {
      return useManagerApi()
        .delete(`nick-agenda/cart/${this.cart.id}/items/${id}`)
        .then((response) => {
          this.$patch({ cart: response.data.data });

          return response;
        });
    },

    resetCart() {
      if (this.cart.id === null) {
        return;
      }

      const patientStore = usePatientStore();

      useManagerApi()
        .put(`nick-agenda/cart/${this.cart.id}/cancel`)
        .then((response) => {
          this.$patch({ cart: response.data.data });
          patientStore.hydrate();
        });

      this.$reset();
    },

    async completeCart() {
      if (this.cart.id === null) {
        return;
      }

      try {
        const response = await useManagerApi().put(
          `nick-agenda/cart/${this.cart.id}/complete`
        );
        this.$reset();

        return response.data.data;
      } catch (e) {
        if (e?.response?.data) {
          const response = e.response.data;
          if (
            422 === response.status &&
            "cart_ineligible_to_completion_unavailable_slots" ===
              response.detail
          ) {
            throw e;
          }
        }

        this.error = e?.response?.data || e;

        throw e;
      }
    },

    isCanceled() {
      if (this.cart.id === null) {
        return true;
      }

      return this.cart.status === CANCELED;
    },
  },
  getters: {
    getItemsCount(state) {
      return state.cart.cartItems.data.length;
    },
    cartItems(state) {
      return state.cart.cartItems.data;
    },
    requireMedicalGuideApproval(state) {
      return state.cart.cartItems.data.some(
        (item) => item.requires_medical_guide_approval
      );
    },
  },
});
